import { CustomerType, MyProfileData } from 'common';
import React from 'react';
import { IdentificationSection } from '../identification-section';
import { AddressesSection } from '../addresses-section';
import { MarketingConsentSettingsSection } from '../marketing-consent-settings-section';
import { ContactSection } from '../contact-section';

export const MyProfileUi: React.FC<{ myProfileData?: MyProfileData; customerType?: CustomerType }> = ({
    myProfileData,
}) => {
    if (!myProfileData) {
        return null;
    }
    const { identification, addresses, contactDetails, marketConsentSettings } = myProfileData;

    return (
        <>
            <IdentificationSection identification={identification} />
            <AddressesSection address={addresses} />
            <ContactSection contactDetails={contactDetails} />
            {identification?.customerType === 'Private' && (
                <MarketingConsentSettingsSection marketingConsentSettings={marketConsentSettings} />
            )}
        </>
    );
};
