import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { MyProfile } from 'components/my-profile/MyProfile';
import { Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';

export const MyProfilePage: React.FC = () => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'contact-section.consult-view';

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t(`headline`)}</Heading>
            <Notification status={NotificationStatus.info} className={`u-mb`}>
                {t(`${translationPrefix}.info-alert`)}
                <br />
                {t(`${translationPrefix}.info-alert-2`)}
                <a href={t(`${translationPrefix}.info-alert-link`)} target="_blank" rel="noopener noreferrer">
                    {t(`${translationPrefix}.info-alert-3`)}
                </a>
            </Notification>
            <MyProfile />
        </ContentSection>
    );
};
