import React from 'react';
import { openInSameWindow, Spinner, useAuthentication } from '@cp-shared-5/frontend-ui';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath, loginRedirectPagePath } from '../../components/navigation/paths';
import { landingPageUrl } from '../../config';

export const LandingPage: React.FC = () => {
    const { isAuthenticated } = useAuthentication();
    const history = useHistory();

    if (!isAuthenticated) {
        if (landingPageUrl) {
            openInSameWindow(landingPageUrl)();
        } else {
            history.push(loginRedirectPagePath());
        }
    } else {
        history.push(dashboardPagePath());
    }

    return <Spinner center />;
};
