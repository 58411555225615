import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { CustomerType, Identification, formatAsDate } from 'common';
import React from 'react';
import { useTranslation } from 'react-i18next';

type IdentificationSectionProps = {
    identification?: Identification;
    customerType?: CustomerType;
};
export const IdentificationSection: React.FC<IdentificationSectionProps> = ({ identification }) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'identification';

    const getIdentifcationList = (): DefinitionListItem[] => {
        const noValuePlaceholder = '-';
        let prefix;
        switch (identification?.customerType) {
            case 'Private':
                prefix = `${translationPrefix}.privateCustomerData`;
                const { firstName, lastName, birthDate, vatNumber } = identification;
                return [
                    {
                        label: t(`${prefix}.name`),
                        value: `${firstName ?? '-'} ${lastName ?? ''}`,
                        testId: 'identification-details-name',
                    },
                    {
                        label: t(`${prefix}.birthDate`),
                        value: birthDate ? formatAsDate(birthDate) : noValuePlaceholder,
                        testId: 'identification-details-birth-date',
                    },
                    {
                        label: t(`${prefix}.idCardNumber`),
                        value: vatNumber ?? noValuePlaceholder,
                        testId: 'identification-details-id',
                    },
                ];
            case 'Business':
                prefix = `${translationPrefix}.businessCustomerData`;
                const { companyName } = identification;
                return [
                    {
                        label: t(`${prefix}.companyName`),
                        value: companyName ?? noValuePlaceholder,
                        testId: 'identification-details-company-name',
                    },
                    {
                        label: t(`${prefix}.representativeName`),
                        value: `${identification.legalRepresentantive ?? '-'}`,
                        testId: 'identifcation-details-representative-name',
                    },
                    {
                        label: t(`${prefix}.vatNumber`),
                        value: `${identification.vatNumber ?? '-'}`,
                        testId: 'identifaction-details-vatNumber',
                    },
                ];
            default:
                return [];
        }
    };

    const identifactionList: DefinitionListItem[] = getIdentifcationList();

    if (!identification) {
        return null;
    }

    return (
        <DataOverview title={t(`${translationPrefix}.title`)}>
            <DefinitionListHorizontal list={identifactionList} />
        </DataOverview>
    );
};
