import React from 'react';
import { CookiePolicy as CookiePolicyShared, HeroImage } from '@cp-shared-5/frontend-ui';
import { ContentSection, PageWrap } from '@vwfs-bronson/bronson-react';
import { CookiePolicyPage } from 'common';

export const CookiePolicyUi: React.FC<{ cookiePolicy?: CookiePolicyPage }> = ({ cookiePolicy }) => {
    if (!cookiePolicy) {
        return null;
    }

    const { teaser, content } = cookiePolicy;

    const teaserComponent = teaser ? <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow /> : null;

    return (
        <>
            {teaserComponent}
            <ContentSection>
                <PageWrap size={'medium'}>
                    <CookiePolicyShared textAboveTable={content.text} title={content.title} />
                </PageWrap>
            </ContentSection>
        </>
    );
};
