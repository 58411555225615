import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsProvider, MasterPage as MasterPageShared, useAuthentication } from '@cp-shared-5/frontend-ui';
import { NavigationBar } from '../../components/navigation';
import { IconFooter, LegalFooter } from '../../components/footer';
import { registrationPagePath } from '../../components/navigation/paths';
import { useMyProfile } from '../../components/my-profile/useMyProfile';

// eslint-disable-next-line react/display-name
const MasterPageComponent: React.FC<{ isAuthenticated: boolean | undefined }> = memo(
    ({ isAuthenticated, children }) => {
        const { t } = useTranslation();
        const { data } = useMyProfile();

        return (
            <AnalyticsProvider
                market={'GR'}
                version={'1'}
                releaseDate="tbd"
                language={'gr'}
                brand={'blue'}
                registrationPath={registrationPagePath()}
                loginPath={'/authentication/callback'}
                userGroup={isAuthenticated ? data?.identification?.customerType : undefined}
                isAuthenticated={isAuthenticated}
            >
                <MasterPageShared
                    navigationBar={<NavigationBar isAuthenticated={isAuthenticated} />}
                    iconFooter={<IconFooter />}
                    legalFooter={<LegalFooter />}
                    label={t('back-to-top')}
                >
                    {children}
                </MasterPageShared>
            </AnalyticsProvider>
        );
    },
);
MasterPageComponent.displayName = 'MasterPageComponent';
export const MasterPage: React.FC = ({ children }) => {
    const { isAuthenticated } = useAuthentication();

    return <MasterPageComponent isAuthenticated={isAuthenticated}>{children}</MasterPageComponent>;
};
