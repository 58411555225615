import {
    DATE_FORMAT,
    getPersonRegistrationEndpoint,
    PersonRegistrationData,
    RegistrationError,
    RegistrationResult,
} from 'common';
import { formatCpDate, WithDefaultCpIntegrationErrors } from '@cp-shared-5/common-utilities';
import { CpDataApi } from 'cp-xhr';
import { parseErrorResponse } from '@cp-shared-5/frontend-integration';
import { PersonFormProps } from '../types';
import moment from 'moment-timezone';

export const handleSubmit = (
    formValues: PersonFormProps,
    successCallback: (registrationResult: RegistrationResult, registrationData: PersonFormProps) => void,
    errorCallback: (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => void,
): void => {
    const { vatNumber, firstName, lastName, birthDate } = formValues;
    const registrationData: PersonRegistrationData = {
        vatNumber,
        firstName,
        lastName,
        birthDate: formatCpDate(moment.utc(birthDate, DATE_FORMAT)).toCpDate(),
    };
    CpDataApi.post(getPersonRegistrationEndpoint(), registrationData)
        .then((response) => {
            const responseData: RegistrationResult = response.data;
            successCallback(responseData, formValues);
        })
        .catch((error) => {
            const { code: errorCode, message: errorMessage } = parseErrorResponse<RegistrationError>(error);
            errorCallback(errorCode, errorMessage);
        });
};
