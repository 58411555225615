export function getContractsEndpoint(): string {
    return '/contracts';
}
export function getContractDetailsEndpoint(contractNumber: string): string {
    return `${getContractsEndpoint()}/${contractNumber}`;
}

export function getContractPartiesEndpoint(contractNumber: string): string {
    return `${getContractDetailsEndpoint(contractNumber)}/parties`;
}
