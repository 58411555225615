import { ErrorNotification } from 'components/notifications/error/ErrorNotification';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@vwfs-bronson/bronson-react';

export type NoConnectionViewProps = {
    cancelEditing: () => void;
};

export const NoConnectionView: React.FC<NoConnectionViewProps> = ({ cancelEditing }) => {
    const { t } = useTranslation();

    return (
        <>
            <ErrorNotification testId={'no-connection'} className={`u-mb`} />
            <Button secondary onClick={cancelEditing} testId="cancelButton" type="btn">
                {t('translation:editable-section-nav.back')}
            </Button>
        </>
    );
};
