import React from 'react';
import { Greeting as GreetingShared } from '@cp-shared-5/frontend-ui';
import { Identification } from '../../../common';

export const GreetingUi: React.FC<{ identification?: Identification }> = ({ identification }) => {
    if (!identification) {
        return null;
    }
    const fullGreetingText =
        identification.customerType === 'Private'
            ? `${identification.firstName} ${identification.lastName}`
            : identification.companyName;
    return <GreetingShared fullGreetingText={fullGreetingText} />;
};
