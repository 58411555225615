import React from 'react';
import {
    GenericErrorPage as GenericErrorPageComponent,
    GenericErrorType,
} from '../../components/error-pages/generic-error';
import { getUrlSearchParams } from '../../utils';

export const GenericErrorPage: React.FC = () => {
    const errorTypeParam = getUrlSearchParams().get('errorType');
    return <GenericErrorPageComponent errorType={errorTypeParam as GenericErrorType} />;
};
