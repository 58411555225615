import { HeroImage, StaticPageLoadingPlaceholder, useAnalyticsErrorPageTracker } from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React, { useCallback } from 'react';
import { useUnauthorizedErrorPage } from './useUnauthorizedErrorPage';
import { useLogin } from '../../../auth/useLogin';

const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

export const UnauthorizedErrorPage: React.FC = () => {
    const { cmsContent: unauthorizedErrorPage, isLoading, loadingError } = useUnauthorizedErrorPage();
    useAnalyticsErrorPageTracker('unauthorizedError', !!unauthorizedErrorPage);

    const login = useLogin();
    const onClick = useCallback(() => {
        login();
    }, [login]);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={unauthorizedErrorPage?.pretitle}
            title={unauthorizedErrorPage?.title || ''}
            subTitle={unauthorizedErrorPage?.subTitle}
            buttonText={unauthorizedErrorPage?.buttonText}
            clickHandler={onClick}
            loadingPlaceholder={<StaticPageLoadingPlaceholder withHeroTeaser={false} />}
            inverted
        />
    );
};
