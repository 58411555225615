import React from 'react';
import { DefinitionList, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { Heading } from '@vwfs-bronson/bronson-react';
import { getGeneralSectionListItems } from '../utils';
import { FinancialDetails } from '../../../../../common';

type GeneralSectionProps = {
    details: FinancialDetails;
};

export const GeneralSection: React.FC<GeneralSectionProps> = ({ details }) => {
    const { t, f } = useTranslationWithFormatting('financial-details');
    if (!details) return null;
    const itemList: DefinitionListItem[] = getGeneralSectionListItems(t, f, details);

    return (
        <>
            <Heading className={'c-data-overview__header u-pt'} level={'6'}>
                {t('general-section.headline')}
            </Heading>
            <DefinitionList split={true} list={itemList} />
        </>
    );
};
