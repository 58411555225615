import React from 'react';
import { useVehicleImage } from './useVehicleImage';
import { useVehicleDetails } from './useVehicleDetails';
import { VehicleImageView } from '@cp-shared-5/apis';
import { VehicleDetailsAndImageUi } from './ui';
import { CPDate } from '@cp-shared-5/common-utilities';

type VehicleDetailsAndImageProps = {
    vin: string;
    encryptedVin: string;
    brandModelType?: string;
    licensePlate?: string;
    registrationDate?: CPDate;
    defaultView?: VehicleImageView;
};

export const VehicleDetailsAndImage: React.FC<VehicleDetailsAndImageProps> = ({
    vin,
    encryptedVin,
    brandModelType,
    licensePlate,
    registrationDate,
    defaultView = VehicleImageView.EXTERIOR_FRONT_LEFT,
}) => {
    const {
        data: vehicleImages,
        isLoading: isLoadingVehicleImage,
        loadingError: vehicleImageLoadingError,
    } = useVehicleImage(encryptedVin, defaultView);

    const {
        data: vehicleDetails,
        isLoading: isLoadingVehicleDetails,
        loadingError: vehicleDetailsLoadingError,
    } = useVehicleDetails(encryptedVin);

    return (
        <VehicleDetailsAndImageUi
            vin={vin}
            brandModelType={brandModelType}
            vehicleDetails={vehicleDetails}
            isLoadingVehicleDetails={isLoadingVehicleDetails}
            vehicleDetailsLoadingError={!!vehicleDetailsLoadingError}
            vehicleImages={vehicleImages}
            isLoadingVehicleImage={isLoadingVehicleImage}
            vehicleImageLoadingError={!!vehicleImageLoadingError}
            licensePlate={licensePlate}
            registrationDate={registrationDate}
            defaultView={defaultView}
        />
    );
};
