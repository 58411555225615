import { GenericErrorType } from './enums';
import { GenericError, GenericErrorPage } from '../../../../common';

export const getGenericErrorTranslation = (
    errorType?: GenericErrorType | null,
    genericError?: GenericErrorPage,
): GenericError | undefined => {
    if (errorType === GenericErrorType.CONSENT_DENIED_ERR) {
        return genericError?.consentDeniedError;
    }
    return genericError?.generalError;
};
