import React, { Suspense } from 'react';
import { AuthenticationProvider, AutoLogout, ScrollToTopOnNavigation, Spinner } from '@cp-shared-5/frontend-ui';
import {
    logoutPath,
    cookiePolicyPath,
    dashboardPagePath,
    faqPagePath,
    forbiddenPagePath,
    genericErrorPagePath,
    landingPagePath,
    legalNoticePath,
    myProfilePagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    privacyPolicyPath,
    registrationPagePath,
    changeContactDetailsPath,
    loginRedirectPagePath,
} from 'components/navigation/paths';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { history } from './store/rootReducer';
import { AuthenticatedRoute, AuthorizedRoute } from './auth';
import { AxiosConfiguration } from './auth/AxiosConfiguration';
import {
    PrivacyPolicyPage,
    LegalNoticePage,
    CookiePolicyPage,
    ForbiddenPage,
    LandingPage,
    MasterPage,
    NotAuthorizedPage,
    NotFoundPage,
    DashboardPage,
} from './pages';
import { FaqPage } from 'pages/faq-page';
import { GenericErrorPage } from './pages/generic-error-page';
import { MyProfilePage } from 'pages/my-profile-page';
import { LogoutPage } from './pages/logout-page/LogoutPage';
import { RegistrationPage } from './pages/registration-page';
import { LoginRedirect } from './components/login-redirect';

const App: React.FC = () => (
    <div className="App">
        <Suspense fallback={<Spinner fullPage center />}>
            <AuthenticationProvider loadingComponent={<Spinner center />}>
                <AutoLogout
                    idleTimeInMinutes={30}
                    redirectUri={`${window.location.origin}${logoutPath()}?logout-type=auto`}
                >
                    <AxiosConfiguration>
                        <ConnectedRouter history={history}>
                            <LastLocationProvider>
                                <ScrollToTopOnNavigation
                                    exceptions={[
                                        { from: /\S*/, to: changeContactDetailsPath() },
                                        { from: new RegExp(`${myProfilePagePath()}/\\S*`), to: myProfilePagePath() },
                                    ]}
                                />
                                <MasterPage>
                                    <Switch>
                                        <Route exact path={landingPagePath()}>
                                            <LandingPage />
                                        </Route>
                                        <Route path={loginRedirectPagePath()}>
                                            <LoginRedirect />
                                        </Route>
                                        <Route path={faqPagePath()}>
                                            <FaqPage />
                                        </Route>
                                        <AuthorizedRoute path={dashboardPagePath()}>
                                            <DashboardPage />
                                        </AuthorizedRoute>
                                        <AuthorizedRoute path={myProfilePagePath()}>
                                            <MyProfilePage />
                                        </AuthorizedRoute>
                                        <AuthenticatedRoute path={registrationPagePath()}>
                                            <RegistrationPage />
                                        </AuthenticatedRoute>
                                        <Route path={forbiddenPagePath()}>
                                            <ForbiddenPage />
                                        </Route>
                                        <Route path={notAuthorizedPagePath()}>
                                            <NotAuthorizedPage />
                                        </Route>
                                        <Route path={notFoundPagePath()}>
                                            <NotFoundPage />
                                        </Route>
                                        <Route path={genericErrorPagePath()}>
                                            <GenericErrorPage />
                                        </Route>
                                        <Route path={cookiePolicyPath()}>
                                            <CookiePolicyPage />
                                        </Route>
                                        <Route path={privacyPolicyPath()}>
                                            <PrivacyPolicyPage />
                                        </Route>
                                        <Route path={legalNoticePath()}>
                                            <LegalNoticePage />
                                        </Route>
                                        <Route path={logoutPath()}>
                                            <LogoutPage />
                                        </Route>
                                        <Route path="*">
                                            <Redirect to={notFoundPagePath()} />
                                        </Route>
                                    </Switch>
                                </MasterPage>
                            </LastLocationProvider>
                        </ConnectedRouter>
                    </AxiosConfiguration>
                </AutoLogout>
            </AuthenticationProvider>
        </Suspense>
    </div>
);

export default App;
