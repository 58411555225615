import { CpDataApi } from 'cp-xhr';
import { ContractParties } from '../../../../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-5/frontend-integration';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<ContractParties, DefaultBusinessMarketApiErrorCode>(
    {
        dataName: 'contractParties',
        fetchCallback(link: string) {
            return CpDataApi.get(link).then((response) => response.data);
        },
    },
);

export default reducer;
export const fetchContractParties = fetchData;
