import { formatCpDate } from '@cp-shared-5/common-utilities';
import { LegalFooter as LegalFooterUi, LegalFooterLoadingPlaceholder } from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useLegalFooter } from './useLegalFooter';

const LegalFooterWithHandlers = withLoadingAndNoConnectionHandler(LegalFooterUi);

export const LegalFooter: React.FC = () => {
    const { cmsContent: legalFooter, isLoading, loadingError } = useLegalFooter();
    const currentYear = formatCpDate().toMoment().year();

    const { copyrightText, links = [], legalText } = legalFooter || {};

    return (
        <LegalFooterWithHandlers
            copyrightText={copyrightText && `${copyrightText} ${currentYear}`}
            legalText={
                <>
                    {legalText?.section1}
                    <br />
                    <br />
                    {legalText?.section2}
                    <br />
                    <br />
                    {legalText?.section3}
                    <br />
                    <br />
                    <a href={legalText?.linkUrl} target="_blank" rel="noopener noreferrer">
                        {legalText?.linkText}
                    </a>
                </>
            }
            isLoading={isLoading}
            links={links}
            hasError={!!loadingError}
            loadingPlaceholder={<LegalFooterLoadingPlaceholder />}
        />
    );
};
