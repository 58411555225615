import { TFunction } from 'i18next';
import { DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { FormatterFunction, TranslationFormat } from 'localization/useTranslationWithFormatting';
import { FinancialContract, FinancialContractType, FinancialDetails } from '../../../../common';

export const getGeneralSectionListItems = (
    t: TFunction,
    f: FormatterFunction,
    financialDetails: FinancialDetails,
): DefinitionListItem[] => {
    const { contractStartDate, contractEndDate, term } = financialDetails;
    const startDateItem: DefinitionListItem = {
        label: t('general-section.start-date'),
        value: contractStartDate ? f(contractStartDate, TranslationFormat.DATE) : '-',
        testId: 'general-section-start-date',
    };
    const endDateItem: DefinitionListItem = {
        label: t('general-section.end-date'),
        value: contractEndDate ? f(contractEndDate, TranslationFormat.DATE) : '-',
        testId: 'general-section-end-date',
    };
    const termItem: DefinitionListItem = {
        label: t('general-section.term'),
        value: term ? `${term}` : '-',
        testId: 'general-section-term',
    };

    return [startDateItem, endDateItem, termItem];
};

const getListItem = (
    fieldName: string,
    value: string,
    t: TFunction,
    f: FormatterFunction,
): DefinitionListItem | null => {
    const items: {
        listItem: DefinitionListItem;
        key: string;
        format?: 'date' | 'percentage' | 'currency' | 'number' | 'ordinal-number';
    }[] = [
        {
            key: 'financeValueOfContract',
            format: 'currency',
            listItem: {
                label: t('financing-section.finance-value.label'),
                value: '',
                testId: 'financing-section-start-date',
            },
        },
        {
            key: 'downPayment',
            format: 'currency',
            listItem: {
                label: t('financing-section.down-payment.label'),
                value: '',
                testId: 'financing-section-start-date',
            },
        },
        {
            key: 'balloonAmount',
            format: 'currency',
            listItem: {
                label: t('financing-section.balloon-amount.label'),
                value: '',
                testId: 'financing-section-start-date',
            },
        },
        {
            key: 'monthlyDueDay',
            format: 'ordinal-number',
            listItem: {
                label: t('financing-section.monthly-due-day.label'),
                value: '',
                testId: 'financing-section-start-date',
            },
        },
        // Descoped for 1.0 release, uncomment this section after market approval to include this field again
        // TODO
        // {
        //     key: 'nominalInterestRate',
        //     format: 'percentage',
        //     listItem: {
        //         label: t('financing-section.nominal-interest-rate.label'),
        //         value: '',
        //         testId: 'financing-section-start-date',
        //     },
        // },
        // Descoped for 1.0 release, uncomment this section after market approval to include this field again
        // TODO
        // {
        //     key: 'pendingCapital',
        //     format: 'currency',
        //     listItem: {
        //         label: t('financing-section.pending-capital.label'),
        //         value: '',
        //         testId: 'financing-section-start-date',
        //     },
        // },
    ];
    const item = items.find(({ key }) => key === fieldName);
    let formattedValue;
    switch (item?.format) {
        case 'date':
            formattedValue = value ? f(value, TranslationFormat.DATE) : '-';
            break;
        case 'percentage':
            formattedValue = Number(value) >= 0 ? f(Number(value), TranslationFormat.PERCENTAGE) : '-';
            break;
        case 'currency':
            formattedValue = Number(value) ? f(Number(value), TranslationFormat.CURRENCY) : '-';
            break;
        case 'number':
            formattedValue = Number(value) ? f(Number(value), TranslationFormat.NUMBER) : '-';
            break;
        case 'ordinal-number':
            formattedValue = value ? f(value, TranslationFormat.ORDINAL_NUMBER) : '-';
            break;
        default:
            formattedValue = value || '-';
    }
    return item ? { ...item.listItem, value: formattedValue } : null;
};

const getListItems = (fields: string[], values: string[], t: TFunction, f: FormatterFunction): DefinitionListItem[] => {
    return fields.reduce((acc: DefinitionListItem[], field: string, index: number): DefinitionListItem[] => {
        const item = getListItem(field, values[index], t, f);
        if (item) acc.push(item);
        return acc;
    }, []);
};

export const getFinancingSectionListItems = (
    t: TFunction,
    f: FormatterFunction,
    contract: FinancialContract,
): DefinitionListItem[] => {
    switch (contract.contractType) {
        case FinancialContractType.CONSUMER_CREDIT:
        case FinancialContractType.AUTO_CREDIT:
        case FinancialContractType.FINANCIAL_LEASING: {
            const shownFields: (keyof typeof contract.financial)[] = contract.isActive
                ? ['financeValueOfContract', 'downPayment', 'monthlyDueDay', 'nominalInterestRate', 'pendingCapital']
                : ['financeValueOfContract', 'downPayment', 'nominalInterestRate'];
            const values: string[] = shownFields.map((field) =>
                contract.financial[field] ? `${contract.financial[field]}` : '',
            );
            return getListItems(shownFields, values, t, f);
        }
        case FinancialContractType.OPERATIVE_LEASING: {
            const shownFields: (keyof typeof contract.financial)[] = contract.isActive
                ? ['downPayment', 'monthlyDueDay', 'nominalInterestRate']
                : ['downPayment', 'nominalInterestRate'];
            const values: string[] = shownFields.map((field) =>
                contract.financial[field] ? `${contract.financial[field]}` : '',
            );
            return getListItems(shownFields, values, t, f);
        }
    }
};
