import { AddressBase } from 'common';

export const addressValuesAreEmpty = (address: AddressBase) =>
    !address?.street && !address?.postalCode && !address?.city && !address?.country;

export const getPhoneWithoutZeroOnBeginning = (phone: string | undefined): string | undefined => {
    if (!phone) return phone;
    return phone[0] === '0' ? phone.substring(1) : phone;
};

export const removeWhitespaces = (str: string | undefined): string | undefined => {
    return str?.replace(/\s/g, '');
};
