import { createCmsContentSlice } from 'cms-integration';
import { CookiePolicyPage, getCookiePolicyEndpoint } from '../../common';

const { reducer, fetchContent } = createCmsContentSlice<CookiePolicyPage>({
    contentName: 'cookiePolicy',
    contentEndpoint: getCookiePolicyEndpoint,
});

export default reducer;
export const fetchCookiePolicy = fetchContent;
