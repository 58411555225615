import React from 'react';
import { DefinitionList, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { getFinancingSectionListItems } from '../../utils';
import { Heading } from '@vwfs-bronson/bronson-react';
import { FinancialContract } from '../../../../../../common';

type FinancingSectionProps = {
    contract: FinancialContract;
};

export const NonEditView: React.FC<FinancingSectionProps> = ({ contract }) => {
    const { t, f } = useTranslationWithFormatting('financial-details');
    if (!contract.financial) return null;

    const itemList: DefinitionListItem[] = getFinancingSectionListItems(t, f, contract);

    return (
        <>
            <Heading className={'c-data-overview__header u-pt'} level={'6'}>
                {t('financing-section.headline')}
            </Heading>
            <DefinitionList split={true} list={itemList} />
        </>
    );
};
