import React from 'react';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { RegistrationError, RegistrationResult } from 'common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-5/common-utilities';
import {
    CleaveInput,
    preventSubmit,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    ValidatedCheckbox,
    ValidatedInput,
} from '@cp-shared-5/frontend-ui';
import { personIdentificationFormValidationSchema } from './validation';
import { handleSubmit } from './utils';
import { PersonFormProps } from '../types';
import { legalNoticePath } from '../../../../navigation/paths';
import { isEmpty } from 'lodash';
import { useTrackerOnBeforeUnload } from '../../../../../hooks';

type PersonIdentificationFormProps = {
    onSubmitClick: Function;
    onSuccess: (data: RegistrationResult, formData: PersonFormProps) => void;
    onError: (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => void;
};

export const PersonIdentificationForm: React.FC<PersonIdentificationFormProps> = ({
    onSubmitClick,
    onSuccess,
    onError,
}) => {
    const { t } = useTranslation('registration');
    const setLastTouchedField = useTrackerOnBeforeUnload();

    const { onTyping, onSubmit: trackOnSubmit } = useAnalyticsFormTracker({
        startTyping: 'onStartTypingConfirmIdentity',
        confirm: 'onConfirmConfirmIdentiy',
    });

    const { onAction } = useAnalyticsActionTracker('onFormValidationErrorConfirmIdentity');
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);
    const initialListErrors =
        'privateCustomerNumber, firstName, surname, dateOfBirth, confirmTermsAndConditions, confirmPrivacyPolicy';

    const onSubmit = (formValues: PersonFormProps): void => {
        onSubmitClick();
        trackOnSubmit();
        handleSubmit(formValues, onSuccess, onError);
    };

    const labels = {
        vatNumber: {
            text: t('privateCustomer.vatNumber'),
        },
        firstName: t('privateCustomer.firstName'),
        lastName: t('privateCustomer.lastName'),
        birthDate: t('privateCustomer.birthDate'),
        confirmTermsAndConditions: t('termsAndConditions'),
        confirmPrivacyPolicy: t('privacyPolicyCheckbox'),
        privacyPolicyLink: t('privacyPolicyLink'),
    };

    return (
        <Layout.Item>
            <Formik
                initialValues={{
                    vatNumber: '',
                    firstName: '',
                    lastName: '',
                    birthDate: '',
                    confirmTermsAndConditions: false,
                    confirmPrivacyPolicy: false,
                }}
                validationSchema={personIdentificationFormValidationSchema(t)}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form
                        onSubmit={preventSubmit()}
                        onChange={() => {
                            onTyping(formik.errors, formik.touched);
                        }}
                    >
                        <Fieldset>
                            <Fieldset.Row>
                                <CleaveInput
                                    cleaveOptions={{
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={labels.vatNumber.text}
                                    name="vatNumber"
                                    testId={'private-customer-number'}
                                    onFocus={() => setLastTouchedField(labels.vatNumber.text)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInput
                                    label={labels.firstName}
                                    name="firstName"
                                    testId={'first-name'}
                                    onFocus={() => setLastTouchedField(labels.firstName)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInput
                                    label={labels.lastName}
                                    name="lastName"
                                    testId={'surname'}
                                    onFocus={() => setLastTouchedField(labels.lastName)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <CleaveInput
                                    cleaveOptions={{
                                        delimiter: '.',
                                        blocks: [2, 2, 4],
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={labels.birthDate}
                                    name="birthDate"
                                    testId={'date-of-birth'}
                                    onFocus={() => setLastTouchedField(labels.birthDate)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={
                                        <a href={legalNoticePath()} target="_blank" rel="noopener noreferrer">
                                            {labels.confirmTermsAndConditions}
                                        </a>
                                    }
                                    name="confirmTermsAndConditions"
                                    testId={'private-confirm-terms-and-conditions'}
                                    onFocus={() => setLastTouchedField(labels.confirmTermsAndConditions)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={
                                        <a href={labels.privacyPolicyLink} target="_blank" rel="noopener noreferrer">
                                            {labels.confirmPrivacyPolicy}
                                        </a>
                                    }
                                    name="confirmPrivacyPolicy"
                                    testId={'private-confirm-privacy-policy'}
                                    onFocus={() => setLastTouchedField(labels.confirmPrivacyPolicy)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Button
                                    full={true}
                                    onClick={() => {
                                        formik.submitForm();
                                        if (!isEmpty(formik.errors) || isEmpty(formik.touched)) {
                                            const errorToString = getErrors(formik.errors).toString();
                                            if (!errorToString) {
                                                onAction(initialListErrors);
                                            } else onAction(getErrors(formik.errors));
                                        }
                                    }}
                                    testId={'submit-private-registration-button'}
                                >
                                    {t('confirmButton')}
                                </Button>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </Layout.Item>
    );
};
