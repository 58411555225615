import { MarketingCardLoadingPlaceholder } from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { MarketingCardsUi } from './ui/MarketingCardsUi';
import { useMarketingCards } from './useMarketingCards';

const MarketingCardsWithHandlers = withLoadingAndNoConnectionHandler(MarketingCardsUi);

export const MarketingCards: React.FC = () => {
    const { cmsContent: marketingCards, isLoading, loadingError } = useMarketingCards();

    return (
        <MarketingCardsWithHandlers
            isLoading={isLoading}
            marketingCards={marketingCards}
            hasError={!!loadingError}
            loadingPlaceholder={<MarketingCardLoadingPlaceholder />}
        />
    );
};
