import {
    BusinessRegistrationData,
    getBusinessRegistrationEndpoint,
    RegistrationError,
    RegistrationResult,
} from 'common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-5/common-utilities';
import { CpDataApi } from 'cp-xhr';
import { parseErrorResponse } from '@cp-shared-5/frontend-integration';
import { BusinessFormProps } from '../types';

export const handleSubmit = (
    formValues: BusinessFormProps,
    successCallback: (registrationResult: RegistrationResult, registrationData: BusinessFormProps) => void,
    errorCallback: (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => void,
): void => {
    const { companyVatNumber, contractNumber } = formValues;
    const registrationData: BusinessRegistrationData = {
        vatNumber: companyVatNumber,
        contractNumber,
    };
    CpDataApi.post(getBusinessRegistrationEndpoint(), registrationData)
        .then((response) => {
            const responseData: RegistrationResult = response.data;
            successCallback(responseData, formValues);
        })
        .catch((error) => {
            const { code: errorCode, message: errorMessage } = parseErrorResponse<RegistrationError>(error);
            errorCallback(errorCode, errorMessage);
        });
};
