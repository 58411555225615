import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import cookiePolicyReducer from 'components/cookie-policy/CookiePolicySlice';
import forbiddenErrorPageReducer from 'components/error-pages/forbidden/ForbiddenErrorPageSlice';
import genericErrorPageReducer from 'components/error-pages/generic-error/GenericErrorPageSlice';
import notFoundErrorPageReducer from 'components/error-pages/not-found/NotFoundErrorPageSlice';
import unauthorizedErrorPageReducer from 'components/error-pages/unauthorized/UnauthorizedErrorPageSlice';
import faqReducer from 'components/faq/FaqSlice';
import myProfileReducer from 'components/my-profile/MyProfileSlice';
import contractsReducer from 'components/contracts/ContractsSlice';
import contractPartiesReducer from 'components/contracts/contract-details/financial-details/contract-parties/ContractPartiesSlice';
import vehicleImageReducer from 'components/contracts/vehicle-details-and-image/VehicleImageSlice';
import vehicleDetailsReducer from 'components/contracts/vehicle-details-and-image/VehicleDetailsSlice';
import iconFooterReducer from 'components/footer/icon-footer/IconFooterSlice';
import legalFooterReducer from 'components/footer/legal-footer/LegalFooterSlice';
import privacyPolicyReducer from 'components/privacy-policy/PrivacyPolicySlice';
import legalNoticeReducer from 'components/legal-notice/LegalNoticeSlice';
import logoutReducer from 'components/logout/LogoutSlice';
import marketingCardsReducer from 'components/marketing-cards/MarketingCardsSlice';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    myProfile: myProfileReducer,
    contracts: contractsReducer,
    contractParties: contractPartiesReducer,
    vehicleDetailsAndImage: combineReducers({
        vehicleImages: vehicleImageReducer,
        vehicleDetails: vehicleDetailsReducer,
    }),
    content: combineReducers({
        legalFooter: legalFooterReducer,
        iconFooter: iconFooterReducer,
        faq: faqReducer,
        notFoundErrorPage: notFoundErrorPageReducer,
        forbiddenErrorPage: forbiddenErrorPageReducer,
        unauthorizedErrorPage: unauthorizedErrorPageReducer,
        genericErrorPage: genericErrorPageReducer,
        marketingCards: marketingCardsReducer,
        cookiePolicy: cookiePolicyReducer,
        privacyPolicy: privacyPolicyReducer,
        legalNotice: legalNoticeReducer,
        logout: logoutReducer,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
