import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { DATE_FORMAT } from 'common';
import { formatCpDate } from '@cp-shared-5/common-utilities';

export const personIdentificationFormValidationSchema = (t: TFunction) => {
    const translationPrefix = 'form.validation';
    return Yup.object().shape({
        vatNumber: Yup.string()
            .trim()
            .required(t(`${translationPrefix}.privateCustomer.vatNumber-missing`))
            .matches(/^\d+$/, t(`${translationPrefix}.privateCustomer.vatNumber-format`))
            .length(9, t(`${translationPrefix}.privateCustomer.vatNumber-format`)),
        firstName: Yup.string()
            .trim()
            .required(t(`${translationPrefix}.privateCustomer.firstName-missing`)),
        lastName: Yup.string()
            .trim()
            .required(t(`${translationPrefix}.privateCustomer.lastName-missing`)),
        birthDate: Yup.string()
            .required(t(`${translationPrefix}.privateCustomer.birthDate-missing`))
            .test('format', t(`${translationPrefix}.privateCustomer.birthDate-format`), (date) =>
                formatCpDate(date, DATE_FORMAT).isValid(),
            ),
        confirmTermsAndConditions: Yup.bool().oneOf([true], t(`${translationPrefix}.terms-and-conditions`)),
        confirmPrivacyPolicy: Yup.bool().oneOf([true], t(`${translationPrefix}.privacy-policy`)),
    });
};
