import React from 'react';
import { DefinitionList, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { ContractParties } from '../../../../../../common';
import { useTranslation } from 'react-i18next';

export const ContractPartiesUi: React.FC<{ contractParties?: ContractParties }> = ({ contractParties }) => {
    const { t } = useTranslation('contract-parties');

    if (!contractParties) {
        return null;
    }

    const areAllFieldsEmpty =
        !contractParties?.firstHolder &&
        !contractParties?.secondHolder &&
        !contractParties?.thirdHolder &&
        !contractParties?.guarantor1 &&
        !contractParties?.guarantor2;

    if (areAllFieldsEmpty) {
        return null;
    }

    const itemList: DefinitionListItem[] = Object.entries(contractParties)
        .map(([role, name]: [string, string | undefined]) => {
            return {
                label: t(`role.${role}`),
                value: name || '',
                testId: role,
            };
        })
        .filter((element) => !!element.label && !!element.value);

    return itemList && <DefinitionList split={true} list={itemList} />;
};
