import { IconFooter as IconFooterShared, useAnalyticsActionTracker } from '@cp-shared-5/frontend-ui';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentPageName } from '../../../../utils/trackingUtils';
import { IconFooter } from '../../../../common';

export const IconFooterUi: React.FC<{ iconFooter?: IconFooter }> = ({ iconFooter }) => {
    const location = useLocation();
    const currentPageName = getCurrentPageName(location);

    const { onAction: onCallUs } = useAnalyticsActionTracker('onClickCallUsLink');
    const { onAction: onEmailUs } = useAnalyticsActionTracker('onClickEmailLink');

    if (!iconFooter) {
        return null;
    }

    return (
        <IconFooterShared
            title={iconFooter.title}
            items={iconFooter.items}
            trackingFunctions={{
                callUs: (): void => onCallUs('Call us', currentPageName),
                emailUs: (): void => onEmailUs('Contact', currentPageName),
            }}
        />
    );
};
