import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const validationSchema = (t: TFunction, translationPrefix: string) => {
    const requiredEmailError = `${translationPrefix}.email.validation.required`;
    const invalidEmailError = `${translationPrefix}.email.validation.invalid`;
    const requiredMobilePhoneError = `${translationPrefix}.mobile-phone.validation.required`;
    const invalidMobilePhoneError = `${translationPrefix}.mobile-phone.validation.invalid`;
    const beginsWithZeroMobilePhoneError = `${translationPrefix}.mobile-phone.validation.begins-with-zero`;
    const invalidHomePhoneError = `${translationPrefix}.home-phone.validation.invalid`;
    const beginsWithZeroMobileHomeError = `${translationPrefix}.home-phone.validation.begins-with-zero`;

    return Yup.object().shape({
        email: Yup.string().trim().required(t(requiredEmailError)).email(t(invalidEmailError)),
        mobilePhone: Yup.string()
            .trim()
            .transform((value) => (value ? value.replace(/\s/g, '') : ''))
            .required(t(requiredMobilePhoneError))
            .min(9, t(invalidMobilePhoneError))
            .max(9, t(invalidMobilePhoneError))
            .matches(/^[^0].*/, t(beginsWithZeroMobilePhoneError))
            .matches(/^\d+$/, t(invalidMobilePhoneError)),
        homePhone: Yup.string()
            .trim()
            .transform((value) => (value ? value.replace(/\s/g, '') : ''))
            .min(9, t(invalidHomePhoneError))
            .max(20, t(invalidHomePhoneError))
            .matches(/^[^0].*/, t(beginsWithZeroMobileHomeError))
            .matches(/^\d+$/, t(invalidHomePhoneError)),
    });
};
