import { Identification } from '../../common';
import { formatCpDate } from '@cp-shared-5/common-utilities';

export const GreetingWithAllValues: Identification = {
    customerType: 'Private',
    firstName: 'Oliver',
    lastName: 'Prager',
    birthDate: formatCpDate('1990-01-06').toCpDate(),
    vatNumber: 'HP577552',
};

export const GreetingForBusinessCustomer: Identification = {
    customerType: 'Business',
    legalRepresentantive: 'Mateusz Karas',
    companyName: 'ABC Co.',
    vatNumber: 'GR1234567890',
};
