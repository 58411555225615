import { createCmsContentSlice } from 'cms-integration';
import { getPrivacyPolicyEndpoint, PrivacyPolicyPage } from 'common';

const { reducer, fetchContent } = createCmsContentSlice<PrivacyPolicyPage>({
    contentName: 'privacyPolicy',
    contentEndpoint: getPrivacyPolicyEndpoint,
});

export default reducer;
export const fetchPrivacyPolicy = fetchContent;
