import { createCmsContentSlice } from 'cms-integration';
import { getMarketingCardsEndpoint, MarketingCards } from '../../common';

const { reducer, fetchContent } = createCmsContentSlice<MarketingCards>({
    contentName: 'marketingCards',
    contentEndpoint: getMarketingCardsEndpoint,
});

export default reducer;
export const fetchMarketingCards = fetchContent;
