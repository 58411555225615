import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatAsDate, InsuranceContract } from '../../../../../common';
import { ContractSummary, ContractSummaryItemProps } from '@cp-shared-5/frontend-ui';

export type InsuranceProps = {
    contract: InsuranceContract;
};

export const Insurance: React.FC<InsuranceProps> = ({ contract }) => {
    const { isActive, insurer, startDate, endDate } = contract;

    const { t } = useTranslation('insurer-contract-header');

    const items: Array<ContractSummaryItemProps> = [
        {
            value: insurer || '-',
            label: t('insurer'),
            testClass: 'insurer',
        },
        {
            value: formatAsDate(startDate),
            label: t('start-date'),
            testClass: 'start-date',
        },
        {
            value: formatAsDate(endDate),
            label: t('end-date'),
            testClass: 'end-date',
        },
        {
            value: isActive ? t('contract-statuses.active') : t('contract-statuses.inactive'),
            label: t('contract-status'),
            testClass: 'contract-status',
        },
    ];

    return <ContractSummary items={items} />;
};
