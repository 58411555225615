import React from 'react';

import { Button, Card } from '@vwfs-bronson/bronson-react';
import { MarketingCards } from 'common/apis/cms/types/marketing-cards';
import { useAnalyticsActionTracker } from '@cp-shared-5/frontend-ui';

type MarketingCardsUiProps = {
    marketingCards?: MarketingCards;
};

export const MarketingCardsUi: React.FC<MarketingCardsUiProps> = ({ marketingCards }) => {
    const { onAction } = useAnalyticsActionTracker('onLearnMore');

    if (!marketingCards) {
        return null;
    }

    const testId = 'marketingCardTestId';

    return (
        <Card
            element="article"
            title={marketingCards?.title}
            subtitle={<img src={marketingCards.smartIslandUrl} alt="smart-island" />}
            imageSrc={marketingCards?.imageUrl}
            footer={true}
            className={'u-mb'}
            testId={testId}
            buttons={
                <Button
                    link={true}
                    icon="semantic-forward"
                    iconReversed
                    small
                    testId={`${testId}-button`}
                    onClick={() => {
                        window.open(marketingCards?.button.url, '_blank');
                        onAction(marketingCards?.title);
                    }}
                >
                    {marketingCards?.button.label}
                </Button>
            }
        >
            {marketingCards?.description}
        </Card>
    );
};
