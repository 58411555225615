import { Logout, getLogoutEndpoint } from '../../common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<Logout>({
    contentName: 'logout',
    contentEndpoint: getLogoutEndpoint,
});

export default reducer;
export const fetchLogout = fetchContent;
