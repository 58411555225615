import { GenericErrorPage, getGenericErrorPageEndpoint } from '../../../common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<GenericErrorPage>({
    contentName: 'genericErrorPage',
    contentEndpoint: getGenericErrorPageEndpoint,
});

export default reducer;
export const fetchGenericErrorPage = fetchContent;
