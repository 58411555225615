import React, { useCallback } from 'react';
import { HeroImage, StaticPageLoadingPlaceholder } from '@cp-shared-5/frontend-ui';
import { GenericErrorType, getGenericErrorTranslation } from './utils';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { useGenericErrorPage } from './useGenericErrorPage';
import { useLogin } from '../../../auth/useLogin';

const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

export const GenericErrorPage: React.FC<{ errorType: GenericErrorType | null }> = ({ errorType }) => {
    const { cmsContent: genericError, isLoading, loadingError } = useGenericErrorPage();

    const login = useLogin();
    const onClick = useCallback(() => {
        login();
    }, [login]);

    const { title, subtitle, buttonText } = getGenericErrorTranslation(errorType, genericError) || {};

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={title}
            subTitle={subtitle}
            buttonText={buttonText}
            clickHandler={onClick}
            loadingPlaceholder={<StaticPageLoadingPlaceholder withHeroTeaser={false} />}
            inverted
        />
    );
};
