import React from 'react';
import { Accordion, useAnalyticsActionTracker } from '@cp-shared-5/frontend-ui';
import { useTranslation } from 'react-i18next';
import { ContractDetails } from '../../contract-details';
import { Contract } from '../../../../common';

export const ContractBody: React.FC<{ contract: Contract }> = ({ contract }) => {
    const { onAction } = useAnalyticsActionTracker('onOpenContractAccordion');
    const { t } = useTranslation('contracts');

    if (!contract || !contract.contractNumber || !contract.encryptedContractNumber) {
        return null;
    }

    const getViewChange = (currentViewName: string) => {
        switch (currentViewName) {
            case t('financial-details:headline'):
                return 'Financial details';
            case t('vehicle-details:headline'):
                return 'Vehicle details';
            case t('contract-parties:headline'):
                return 'Contract parties';
            case t('bundled-products:headline'):
                return 'Products / Services included';
        }
    };

    let openItems = [];

    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        const viewChange = getViewChange(refs[refs.length - 1]);
        onAction(viewChange);
    };

    return (
        <Accordion lazyRender={true} onChange={handleOnChange}>
            <ContractDetails contract={contract} />
        </Accordion>
    );
};
