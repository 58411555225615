import moment from 'moment-timezone';
import numeral from 'numeral';

import 'moment/locale/el';
import 'numeral/locales/es';
import { CPDate, formatCpDate } from '@cp-shared-5/common-utilities';

// Check out https://momentjs.com/timezone/
export const GR_TIMEZONE = 'Europe/Athens';

moment.tz.setDefault(GR_TIMEZONE);

export const DATE_FORMAT = 'DD.MM.YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

const locale = 'el';
export const numberFormat = '0,0';
export const numberFormatRoundedOne = '0,0.0';
export const numberFormatRoundedTwo = '0,0.00';
const percentageNumberFormat = '0,0.00';
const numeralFormat = '0o';
const currencyFormat = '0,0.00';
const longDateFormat = 'DD.MM.YYYY';

export function changeLocale(locale: 'en' | 'el'): void {
    moment.locale(locale);
    /**
     * Greek language 'el' is not available in numeral library - that's why 'es' is in use here with similar formatting
     */
    const numeralLocale = locale === 'el' ? 'es' : locale;
    numeral.locale(numeralLocale);
}

changeLocale(locale);

export function formatAsCurrency(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(currencyFormat)} €` : '';
}

export function formatAsDate(date?: CPDate): string {
    return date ? formatCpDate(date).format(longDateFormat) : '';
}

export function formatAsNumber(value?: number, format: string = numberFormat): string {
    return typeof value === 'number' ? numeral(value).format(format) : '';
}

export function formatAsNumeral(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numeralFormat) : '';
}

export function formatAsDistance(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numberFormat) : '';
}

export function formatAsEmission(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} g/km` : '';
}

export function formatAsPercentageRate(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(percentageNumberFormat)}%` : '';
}

export function formatAsOrdinalNumber(value?: number | string): string {
    return value ? `${value}.` : '';
}

export function getValue(formattedValue?: string): number | undefined {
    return formattedValue ? numeral(formattedValue).value() || undefined : undefined;
}
