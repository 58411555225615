import React from 'react';
import { FinancialDetailsUi } from './ui';
import { useTranslation } from 'react-i18next';
import { AccordionItem } from '@cp-shared-5/frontend-ui';
import { FinancialContract } from '../../../../common';

export type FinancialDetailsProps = {
    contract: FinancialContract;
};

export const FinancialDetails: React.FC<FinancialDetailsProps> = ({ contract }) => {
    const { t } = useTranslation('financial-details');

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-none'}>
            <div>
                <FinancialDetailsUi contract={contract} />
            </div>
        </AccordionItem>
    );
};
