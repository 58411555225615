import { AbstractDataState, useGetContractBasedApiData } from '@cp-shared-5/frontend-integration';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { ContractParties, getContractPartiesEndpoint } from '../../../../../common';
import { fetchContractParties } from './ContractPartiesSlice';
import { ContractPartiesDataSelector } from './ContractPartiesDataSelector';

export function useContractParties(
    encryptedContractNumber: string,
): AbstractDataState<ContractParties, DefaultBusinessMarketApiErrorCode> {
    return useGetContractBasedApiData(
        encryptedContractNumber,
        fetchContractParties,
        ContractPartiesDataSelector,
        getContractPartiesEndpoint(encryptedContractNumber),
    );
}
