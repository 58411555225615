import React from 'react';
import { HeroImage } from '@cp-shared-5/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { PrivacyPolicyPage } from 'common';
import { DownloadLink } from 'components/download-link/DownloadLink';

export const PrivacyPolicyUi: React.FC<{ privacyPolicy?: PrivacyPolicyPage }> = ({ privacyPolicy }) => {
    if (!privacyPolicy) {
        return null;
    }
    const { teaser, content } = privacyPolicy;

    const teaserComponent = teaser ? <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow /> : null;

    const title = content?.title ? <h3>{content.title}</h3> : null;

    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium">
                {title}
                <div dangerouslySetInnerHTML={{ __html: content.text }} />
                <DownloadLink label={content.pdf} href={content.href} />
            </ContentSection>
        </>
    );
};
