import React from 'react';
import { Contract, isFinancialContract } from '../../../common';
import { FinancialDetails } from './financial-details';
import { ContractParties } from './financial-details/contract-parties';

export const ContractDetails: React.FC<{ contract: Contract }> = ({ contract }) => {
    const { encryptedContractNumber } = contract;
    if (!encryptedContractNumber) {
        return null;
    }

    return (
        <>
            {isFinancialContract(contract) && <FinancialDetails contract={contract} />}
            <ContractParties encryptedContractNumber={encryptedContractNumber} />
        </>
    );
};
