import React from 'react';
import { Hr } from '@vwfs-bronson/bronson-react';
import { NonEditView } from './non-edit-view/NonEditView';
import { FinancialContract } from '../../../../../common';

type FinancingSectionProps = {
    contract: FinancialContract;
};

export const FinancingSection: React.FC<FinancingSectionProps> = ({ contract }) => {
    return (
        <>
            <Hr className={'u-mb-none'} />
            <NonEditView contract={contract}></NonEditView>
        </>
    );
};
