import { CPDate } from '@cp-shared-5/common-utilities';

export type PersonRegistrationData = {
    vatNumber: string;
    firstName: string;
    lastName: string;
    birthDate: CPDate;
};

export type BusinessRegistrationData = {
    contractNumber: string;
    vatNumber: string;
};

export type RegistrationResult = {
    phoneNumberEnding: string;
    encryptedCustomerId: string;
    tanExpiresAt: CPDate;
};

export interface UserRegistrationResult {
    /*
     * An identifier whether user is registered in CustomerPortal or not
     */
    isRegistered: boolean;
}

export enum RegistrationError {
    PRIVATE_CUSTOMER_NOT_FOUND = 'PRIVATE_CUSTOMER_NOT_FOUND',
    BUSINESS_CUSTOMER_NOT_FOUND = 'BUSINESS_CUSTOMER_NOT_FOUND',
    NOT_FOUND_BY_NAME = 'NOT_FOUND_BY_NAME',
    CUSTOMER_BIRTHDATE_NOT_MATCH = 'CUSTOMER_BIRTHDATE_NOT_MATCH',
    ALREADY_REGISTERED = 'ALREADY_REGISTERED',
    PHONE_NUMBER_NOT_FOUND = 'PHONE_NUMBER_NOT_FOUND',
    INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
    INCORRECT_CUSTOMER_TYPE = 'INCORRECT_CUSTOMER_TYPE',
    CONTRACT_NOT_FOUND = 'CONTRACT_NOT_FOUND',
}

export interface VerificationResult {
    phoneNumber: string;
    customerId: string;
}

export type RegistrationSmsVerify = {
    smsToken?: string;
};
