import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { AddressBase } from 'common';
import React from 'react';
import { useTranslation } from 'react-i18next';

type AddressListProps = {
    address?: AddressBase;
    translationPrefix?: string;
};

export const AddressList: React.FC<AddressListProps> = ({ address = {}, translationPrefix }) => {
    const { t } = useTranslation('my-profile');

    const { street, postalCode, city, country } = address;

    const addressList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.street`),
            value: street || '-',
            testId: 'address-details-street',
        },
        {
            label: t(`${translationPrefix}.postalCode`),
            value: postalCode || '-',
            testId: 'address-details-postalCode',
        },
        {
            label: t(`${translationPrefix}.city`),
            value: city || '-',
            testId: 'address-details-city',
        },
        {
            label: t(`${translationPrefix}.country`),
            value: country || '-',
            testId: 'address-details-country',
        },
    ];
    return <DefinitionListHorizontal list={addressList} />;
};
