import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContractSummary, ContractSummaryItemProps } from '@cp-shared-5/frontend-ui';
import { getInactiveContractSummary } from '../utils';
import { AutoCreditContract, formatAsCurrency, formatAsDate } from '../../../../../common';

export type AutoCreditProps = {
    contract: AutoCreditContract;
};

export const AutoCredit: React.FC<AutoCreditProps> = ({ contract }) => {
    const { isActive, nextPaymentDate, nextPaymentAmount } = contract;

    const { t } = useTranslation('financial-contract-header');

    const paymentDateValue = nextPaymentDate ? formatAsDate(nextPaymentDate) : '-';
    const nextPaymentAmountValue = nextPaymentAmount ? formatAsCurrency(nextPaymentAmount) : '-';
    const activeItems: Array<ContractSummaryItemProps> = [
        {
            value: paymentDateValue || '-',
            label: t('next-payment-date'),
            testClass: 'next-payment-date',
        },
        {
            value: nextPaymentAmountValue,
            label: t('next-payment-amount'),
            testClass: 'next-payment-amount',
        },
    ];

    return <ContractSummary items={isActive ? activeItems : getInactiveContractSummary(t, contract)} />;
};
