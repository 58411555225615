import React from 'react';
import { Checkbox } from '@vwfs-bronson/bronson-react';
import { DataOverview } from '@cp-shared-5/frontend-ui';
import { MarketingSettings } from 'common';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

type ConsultViewProps = {
    marketingConsentSettings?: MarketingSettings;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ marketingConsentSettings }) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'marketing-consent-settings.consult-view';

    if (!marketingConsentSettings || isEmpty(marketingConsentSettings)) {
        return null;
    }
    const { email, phone, sms, post } = marketingConsentSettings;
    if (email === false && phone === false && sms === false && post === false) {
        return null;
    }
    const marketingData: Array<{ key: string; value: boolean | undefined }> = [
        { key: 'email', value: email },
        { key: 'phone', value: phone },
        { key: 'sms', value: sms },
        { key: 'post', value: post },
    ];

    return (
        <DataOverview title={t(`${translationPrefix}.title`)}>
            <div className={'u-mb'}>{t(`${translationPrefix}.description`)}</div>
            {marketingData.map(({ key, value }) => (
                <div className={'u-mb-xsmall'} key={key}>
                    <Checkbox checked={value} disabled>
                        {t(`${translationPrefix}.${key}`)}
                    </Checkbox>
                </div>
            ))}
        </DataOverview>
    );
};
