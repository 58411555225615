import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const businessIdentificationFormValidationSchema = (t: TFunction) => {
    const translationPrefix = 'form.validation';
    return Yup.object().shape({
        contractNumber: Yup.string()
            .trim()
            .required(t(`${translationPrefix}.businessCustomer.contractNumber-missing`)),
        companyVatNumber: Yup.string()
            .required(t(`${translationPrefix}.businessCustomer.vatNumber-missing`))
            .matches(/^\d+$/, t(`${translationPrefix}.businessCustomer.vatNumber-invalid-format`)),
        confirmTermsAndConditions: Yup.bool().oneOf([true], t(`${translationPrefix}.terms-and-conditions`)),
        confirmPrivacyPolicy: Yup.bool().oneOf([true], t(`${translationPrefix}.privacy-policy`)),
    });
};
