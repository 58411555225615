import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { ContactDetails } from 'common';
import { getPhoneWithoutZeroOnBeginning } from 'components/my-profile/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ContactListProps = {
    contactDetails: ContactDetails;
};

export const ContactList: React.FC<ContactListProps> = ({ contactDetails }) => {
    const { t } = useTranslation('my-profile');
    const { mobilePhone, homePhone, email } = contactDetails;

    const getFullPhone = (phone?: string): string => {
        if (phone) {
            const withoutZeroOnBeggining = getPhoneWithoutZeroOnBeginning(phone);
            return `+30 ${withoutZeroOnBeggining}`;
        } else {
            return '-';
        }
    };

    const contactList: DefinitionListItem[] = [
        {
            label: t('contact-section.email.label'),
            value: email || '-',
            testId: 'contact-details-email',
        },
        {
            label: t('contact-section.mobile-phone.label'),
            value: getFullPhone(mobilePhone) || '-',
            testId: 'contact-details-mobile-phone',
        },
        {
            label: t('contact-section.home-phone.label'),
            value: getFullPhone(homePhone) || '-',
            testId: 'contact-details-home-phone',
        },
    ];
    return <DefinitionListHorizontal list={contactList} />;
};
