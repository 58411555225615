import { Brand } from './enums';
import { TFunction } from 'i18next';

export const capitalizeWord = (word: string): string => {
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
};

export const getBrand = (brandModelType?: string): Brand | undefined => {
    if (!brandModelType) {
        return;
    }
    const availableBrands = ['AUDI', 'SEAT', 'SKODA', 'ŠKODA', 'VW', 'VOLKSWAGEN'];
    const currentBrand = availableBrands.find((brand) => brandModelType.toUpperCase().includes(brand));
    switch (currentBrand) {
        case 'AUD':
        case 'AUDI':
            return Brand.AUDI;
        case 'SEAT':
            return Brand.SEAT;
        case 'SKODA':
        case 'ŠKODA':
            return Brand.SKODA;
        case 'VW':
        case 'VOLKSWAGEN':
            return Brand.VW;
        default:
            return;
    }
};

export const getBrandTranslation = (brand: string, t: TFunction) => {
    const brandToLowerCase = brand.toLowerCase();
    const prefix = 'vehicle-details.brand';
    if (brandToLowerCase === 'skoda') {
        return t(`${prefix}.skoda`);
    }
    if (brandToLowerCase === 'volkswagen commercial vehicles') {
        return t(`${prefix}.volkswagen-commercial-vehicles`);
    }
    return brand;
};

export const getColorFinishTranslation = (colorFinish: string, t: TFunction) => {
    const colorFinishToLowerCase = colorFinish.toLowerCase();
    const shouldTranslate = ['crystal', 'effect', 'matte', 'metallic', 'pearlescent', 'solid'].find(
        (el) => el === colorFinishToLowerCase,
    );
    return shouldTranslate ? t(`vehicle-details.color-finish.${colorFinishToLowerCase}`) : colorFinish;
};

export const getColorTranslation = (color: string, t: TFunction): string => {
    const colorToLowerCase = color.toLowerCase();
    const shouldTranslate = [
        'beige',
        'black',
        'blue',
        'bronze',
        'brown',
        'burgundy',
        'gold',
        'green',
        'grey',
        'orange',
        'pink',
        'purple',
        'red',
        'silver',
        'transparent',
        'turquoise',
        'violette',
        'white',
        'yellow',
    ].find((el) => el === colorToLowerCase);

    return shouldTranslate ? t(`vehicle-details.color.${colorToLowerCase}`) : color;
};

export const getTransmissionTranslation = (transmission: string, t: TFunction): string => {
    const transmissionToLowerCase = transmission.toLowerCase();
    const shouldTranslate = ['automated', 'automatic', 'direct', 'manual', 'sequential', 'unknown'].find(
        (el) => el === transmissionToLowerCase,
    );

    return shouldTranslate ? t(`vehicle-details.transmission.${transmissionToLowerCase}`) : transmission;
};

export const getFuelTypeTranslation = (fuelType: string, t: TFunction): string => {
    let type: string;
    switch (fuelType) {
        case 'diesel':
            type = 'diesel';
            break;
        case 'electric':
            type = 'electric';
            break;
        case 'gasoline':
            type = 'gasoline';
            break;
        case 'hybridDieselElectric':
            type = 'hybrid-diesel-electric';
            break;
        case 'hybridGasolineElectric':
            type = 'hybrid-gasoline-electric';
            break;
        case 'liquidGas':
            type = 'liquid-gas';
            break;
        case 'naturalGas':
            type = 'natural-gas';
            break;
        case 'unknown':
            type = 'unknown';
            break;
        default:
            type = '';
            break;
    }

    const translatedFuelType = !!type ? t(`vehicle-details.fuelType.${type}`) : fuelType;
    return !!translatedFuelType ? capitalizeWord(translatedFuelType) : '';
};
