import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandableContent, FormSectionGroup } from '@vwfs-bronson/bronson-react';
import { Contract } from '../../../common';
import { groupContractsByVin, sortGroupedContractsByStartDate } from './utils';
import { ContractCard } from '../financial-contract';
import { VehicleDetailsAndImage } from '../vehicle-details-and-image/VehicleDetailsAndImage';
import {
    Notification,
    NotificationStatus,
    useAnalyticsDashboardPageTrackerWithoutUnpaid,
} from '@cp-shared-5/frontend-ui';
import { useLastLocation } from 'react-router-last-location';

export const ContractsUi: React.FC<{
    contracts?: Contract[];
}> = ({ contracts }) => {
    const { t } = useTranslation('contracts');
    const onlyValidContractsList = useMemo(
        () => (contracts || []).filter(({ contractNumber, contractType }) => contractNumber && contractType),
        [contracts],
    );

    const lastLocation = useLastLocation();
    const lastPathname = lastLocation === null ? '/authentication/callback' : lastLocation?.pathname;
    useAnalyticsDashboardPageTrackerWithoutUnpaid(!!contracts?.length, lastPathname);

    const getListOfContracts = useCallback(
        (isActive: boolean) => {
            const filteredContracts = onlyValidContractsList.filter((contract) => contract.isActive === isActive);

            const groupedContractsByVin = groupContractsByVin(filteredContracts);
            const sortedGroupsOfContracts = sortGroupedContractsByStartDate(groupedContractsByVin);

            return sortedGroupsOfContracts.length ? (
                <FormSectionGroup>
                    {sortedGroupsOfContracts.map((contracts) =>
                        contracts.map((contract, contractIndex) => (
                            <React.Fragment key={contract.contractNumber}>
                                {contractIndex === 0 && contract.vehicle.vin && contract.vehicle.encryptedVin && (
                                    <VehicleDetailsAndImage
                                        vin={contract.vehicle.vin}
                                        encryptedVin={contract.vehicle.encryptedVin}
                                        brandModelType={contract.vehicle.brandModelType}
                                        licensePlate={contract.vehicle.licensePlate}
                                        registrationDate={contract.vehicle.registrationDate}
                                    />
                                )}
                                <ContractCard
                                    contract={contract}
                                    key={contract.contractNumber}
                                    className={contractIndex === contracts.length - 1 ? 'u-mb-large' : 'u-mb'}
                                    defaultExpanded={isActive && contractIndex === 0}
                                />
                            </React.Fragment>
                        )),
                    )}
                </FormSectionGroup>
            ) : null;
        },
        [onlyValidContractsList],
    );

    const activeContracts = useMemo(() => getListOfContracts(true), [getListOfContracts]);
    const inactiveContracts = useMemo(() => getListOfContracts(false), [getListOfContracts]);

    if (!contracts) {
        return null;
    }

    return (
        <>
            {!activeContracts && (
                <Notification
                    status={NotificationStatus.info}
                    text={t('no-contracts-for-account-error')}
                    testId={'no-contracts-for-account-error'}
                    className={'u-mb'}
                />
            )}
            {activeContracts}
            {inactiveContracts && (
                <ExpandableContent
                    lazyRender={true}
                    triggerLabel={t('expand.trigger')}
                    noBackground
                    pageWrapClassName={'u-pl-none u-pr-none'}
                >
                    <h4 className="u-text-center">{t('expand.headline')}</h4>
                    <FormSectionGroup>{inactiveContracts}</FormSectionGroup>
                </ExpandableContent>
            )}
        </>
    );
};
