import React from 'react';
import { GeneralSection } from '../general-section/GeneralSection';
import { Hr } from '@vwfs-bronson/bronson-react';
import { FinancingSection } from '../financing-section/FinancingSection';
import { FinancialContract } from '../../../../../common';

export const FinancialDetailsUi: React.FC<{
    contract: FinancialContract;
}> = ({ contract }) => {
    if (!contract.contractType) {
        return null;
    }

    return (
        <>
            <GeneralSection details={contract.financial} />
            <Hr className="u-mb-none" />
            <FinancingSection contract={contract} />
        </>
    );
};
