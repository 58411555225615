import { RegistrationLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { RegistrationUi } from './ui';

export const Registration: React.FC = () => {
    const RegistrationWithHandlers = withLoadingAndNoConnectionHandler(RegistrationUi);
    useAnalyticsPageViewTracker('confirmIdentity');

    return (
        <RegistrationWithHandlers
            isLoading={false}
            hasError={false}
            loadingPlaceholder={<RegistrationLoadingPlaceholder />}
        />
    );
};
