import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionItem, ContractDetailsLoadingPlaceholder } from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from '../../../../integration-wrapper';
import { ContractPartiesUi } from './ui/ContractPartiesUi';
import { useContractParties } from './useContractParties';

type ContractPartiesProps = {
    encryptedContractNumber: string;
};

const ContractPartiesUiWithHandlers = withLoadingAndNoConnectionHandler(ContractPartiesUi);

const ContractPartiesItem: React.FC<ContractPartiesProps> = ({ encryptedContractNumber }) => {
    const { data: contractParties, isLoading, loadingError } = useContractParties(encryptedContractNumber);

    return (
        <ContractPartiesUiWithHandlers
            contractParties={contractParties}
            isLoading={isLoading}
            hasError={!!loadingError}
            loadingPlaceholder={<ContractDetailsLoadingPlaceholder sections={[3]} />}
        />
    );
};

export const ContractParties: React.FC<ContractPartiesProps> = ({ encryptedContractNumber }) => {
    const { t } = useTranslation('contract-parties');

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-none'}>
            <ContractPartiesItem encryptedContractNumber={encryptedContractNumber} />
        </AccordionItem>
    );
};
