import React from 'react';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    ScrollToTopOnNavigation,
    ValidatedInput,
} from '@cp-shared-5/frontend-ui';
import { RegistrationError, TanVerificationError } from 'common';
import { CPDate, WithDefaultCpIntegrationErrors } from '@cp-shared-5/common-utilities';
import { identificationFormValidationSchema } from './validation';
import { NoConnectionNotification } from '../../../../notifications/no-connection/NoConnectionNotification';
import moment from 'moment';

export type FormProps = {
    smsToken: string;
};

type SmsIdentificationFormProps = {
    onResendCode: () => void;
    onSmsCodeConfirmation: (values: FormProps) => void;
    tanExpiresAt: CPDate;
    phoneNumberEnding?: string;
    errorCode?: WithDefaultCpIntegrationErrors<RegistrationError | TanVerificationError>;
};

export const SmsIdentificationForm: React.FC<SmsIdentificationFormProps> = ({
    onResendCode,
    onSmsCodeConfirmation,
    tanExpiresAt,
    phoneNumberEnding,
    errorCode,
}) => {
    const { t } = useTranslation('registration');

    if (errorCode === TanVerificationError.WRONG_SMS_TOKEN && moment(tanExpiresAt).isBefore()) {
        errorCode = TanVerificationError.EXPIRED_SMS_TOKEN;
    }
    const displayForm = !(
        errorCode === TanVerificationError.WRONG_SMS_TOKEN || errorCode === TanVerificationError.IS_LOCKED_OUT
    );
    const displayHelpText = !errorCode || errorCode !== TanVerificationError.IS_LOCKED_OUT;
    const displayResendButton = errorCode === TanVerificationError.WRONG_SMS_TOKEN;

    const isVerificationErrorCode = (
        errorCode: WithDefaultCpIntegrationErrors<RegistrationError | TanVerificationError>,
    ) => {
        return errorCode in TanVerificationError;
    };

    return (
        <>
            <Layout.Item className={'u-mb u-text-center'}>
                <h1>{t('sms-form.head.title')}</h1>
                <p>{t('sms-form.head.info-identification', { phoneNumberEnding })}</p>
                {errorCode && !isVerificationErrorCode(errorCode) && (
                    <ScrollToTopOnNavigation>
                        <NoConnectionNotification />
                    </ScrollToTopOnNavigation>
                )}
                {errorCode && isVerificationErrorCode(errorCode) && (
                    <ScrollToTopOnNavigation>
                        <Notification
                            testId={'error-sms-identification-form'}
                            status={NotificationStatus.error}
                            text={t(`sms-form.errors.${errorCode}`)}
                        />
                    </ScrollToTopOnNavigation>
                )}
            </Layout.Item>
            {displayResendButton && (
                <Layout.Item className="u-mb">
                    <Button full={true} onClick={onResendCode} testId={'resend-code-button'}>
                        {t('sms-form.button-resend')}
                    </Button>
                </Layout.Item>
            )}
            {displayForm && (
                <Layout.Item>
                    <Formik
                        initialValues={{ smsToken: '' }}
                        validationSchema={identificationFormValidationSchema(t)}
                        onSubmit={onSmsCodeConfirmation}
                    >
                        {(formik) => (
                            <Form onSubmit={preventSubmit()}>
                                <Fieldset>
                                    <Fieldset.Row className="u-text-center">
                                        <h3>{t('sms-form.action-title')}</h3>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedInput
                                            label={t('sms-form.input-label')}
                                            testId="sms-token"
                                            name="smsToken"
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Button full={true} onClick={formik.submitForm} testId={'submit-button'}>
                                            {t('sms-form.button-submit')}
                                        </Button>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                </Layout.Item>
            )}
            {displayHelpText && (
                <Layout.Item>
                    <p>
                        {t('sms-form.help-text-1')}
                        <Button link onClick={onResendCode} testId={'resend-code-link'}>
                            {t('sms-form.help-text-resend')}
                        </Button>
                        {t('sms-form.help-text-2')}
                        <a href="https://www.vwfs.gr/FAQFormLoan.html"> {t('sms-form.help-text-link')}</a>
                        {t('sms-form.help-text-3')}
                    </p>
                </Layout.Item>
            )}
        </>
    );
};
