import React from 'react';
import { withLoadingHandler } from 'components/integration-wrapper';
import { GreetingUi } from './ui';
import { GreetingLoadingPlaceholder } from '@cp-shared-5/frontend-ui';
import { useMyProfile } from '../my-profile/useMyProfile';

const GreetingWithHandlers = withLoadingHandler(GreetingUi);

export const Greeting: React.FC = () => {
    const { data, isLoading } = useMyProfile();
    return (
        <GreetingWithHandlers
            isLoading={isLoading}
            identification={data?.identification}
            loadingPlaceholder={<GreetingLoadingPlaceholder />}
        />
    );
};
