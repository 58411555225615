export type PersonFormProps = {
    vatNumber: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    confirmTermsAndConditions: boolean;
    confirmPrivacyPolicy: boolean;
};

export type BusinessFormProps = {
    contractNumber: string;
    companyVatNumber: string;
    confirmTermsAndConditions: boolean;
    confirmPrivacyPolicy: boolean;
};

export enum CustomerType {
    PRIVATE = 'PRIVATE',
    BUSINESS = 'BUSINESS',
}
