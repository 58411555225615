import { CPDate } from '@cp-shared-5/common-utilities';
import {
    AutoCreditFinancialDetails,
    ConsumerCreditFinancialDetails,
    FinancialLeasingFinancialDetails,
    OperativeLeasingFinancialDetails,
} from './financial-details';
import { VehicleAsset } from './vehicle-asset';

export enum FinancialContractType {
    CONSUMER_CREDIT = 'CONSUMER_CREDIT',
    AUTO_CREDIT = 'AUTO_CREDIT',
    FINANCIAL_LEASING = 'FINANCIAL_LEASING',
    OPERATIVE_LEASING = 'OPERATIVE_LEASING',
}

export enum InsuranceContractType {
    WARRANTY_INSURANCE = 'WARRANTY_INSURANCE',
    MOTOR_INSURANCE = 'MOTOR_INSURANCE',
    REINSURANCE_PRODUCT = 'REINSURANCE_PRODUCT',
    MOTOR_INSURANCE_INTERMEDIATION = 'MOTOR_INSURANCE_INTERMEDIATION',
    GUARANTEED_ASSET_PROTECTION = 'GUARANTEED_ASSET_PROTECTION',
    WARRANTY_INSURANCE_INTERMEDIATION = 'WARRANTY_INSURANCE_INTERMEDIATION',
}

export type ContractType = Contract['contractType'];

export type BaseContract = EncryptedContractNumber & {
    isActive: boolean;
    contractNumber?: string;
    endDate?: CPDate;
    startDate?: CPDate;
    vehicle: VehicleAsset;
};

export type ConsumerCreditContract = BaseContract & {
    nextPaymentDate?: CPDate;
    nextPaymentAmount?: number;
    outstandingBalance?: number;
    financial: ConsumerCreditFinancialDetails;
    contractType: FinancialContractType.CONSUMER_CREDIT;
};

export type AutoCreditContract = BaseContract & {
    nextPaymentDate?: CPDate;
    nextPaymentAmount?: number;
    outstandingBalance?: number;
    financial: AutoCreditFinancialDetails;
    contractType: FinancialContractType.AUTO_CREDIT;
};

export type FinancialLeasingContract = BaseContract & {
    nextPaymentDate?: CPDate;
    nextPaymentAmount?: number;
    lastPaymentDate?: CPDate;
    financial: FinancialLeasingFinancialDetails;
    contractType: FinancialContractType.FINANCIAL_LEASING;
};

export type OperativeLeasingContract = BaseContract & {
    nextPaymentDate?: CPDate;
    nextPaymentAmount?: number;
    lastPaymentDate?: CPDate;
    financial: OperativeLeasingFinancialDetails;
    contractType: FinancialContractType.OPERATIVE_LEASING;
};

export type WarrantyInsuranceContract = BaseContract & {
    insurer?: string;
    contractType: InsuranceContractType.WARRANTY_INSURANCE;
};

export type MotorInsuranceContract = BaseContract & {
    insurer?: string;
    contractType: InsuranceContractType.MOTOR_INSURANCE;
};

export type ReinsuranceProductContract = BaseContract & {
    insurer?: string;
    contractType: InsuranceContractType.REINSURANCE_PRODUCT;
};

export type MotorInsuranceIntermediationContract = BaseContract & {
    insurer?: string;
    contractType: InsuranceContractType.MOTOR_INSURANCE_INTERMEDIATION;
};

export type GuaranteedAssetContract = BaseContract & {
    insurer?: string;
    contractType: InsuranceContractType.GUARANTEED_ASSET_PROTECTION;
};

export type WarrantyInsuranceIntermediationContract = BaseContract & {
    insurer?: string;
    contractType: InsuranceContractType.WARRANTY_INSURANCE_INTERMEDIATION;
};

export function buildContract<Type extends ContractType>(
    contractType: Type,
    contract: Partial<Extract<Contract, { contractType: Type }>>,
): Extract<Contract, { contractType: Type }> {
    return {
        contractType,
        isActive: true,
        financial: {},
        vehicle: {},
        ...contract,
    } as Extract<Contract, { contractType: Type }>;
}

export type EncryptedContractNumber = {
    encryptedContractNumber?: string;
};

export type ContractsError = 'NOT_FOUND';

export type FinancialContract =
    | ConsumerCreditContract
    | AutoCreditContract
    | FinancialLeasingContract
    | OperativeLeasingContract;

export type InsuranceContract =
    | WarrantyInsuranceContract
    | MotorInsuranceContract
    | ReinsuranceProductContract
    | MotorInsuranceIntermediationContract
    | GuaranteedAssetContract
    | WarrantyInsuranceIntermediationContract;

export type Contract = FinancialContract | InsuranceContract;

export const isFinancialContract = (contract: Contract): contract is FinancialContract =>
    contract.contractType in FinancialContractType;

export const isInsuranceContract = (contract: Contract): contract is InsuranceContract =>
    contract.contractType in InsuranceContractType;
