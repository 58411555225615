import { ContentSection, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { Contracts } from 'components/contracts';
import { MarketingCards } from 'components/marketing-cards/MarketingCards';
import React from 'react';
import { Greeting } from '../../components/greeting';
import { useTranslation } from 'react-i18next';

export const DashboardPage: React.FC = () => {
    const { t } = useTranslation('dashboard');

    return (
        <ContentSection pageWrapSize="medium">
            <Layout>
                <Layout.Item className={'u-mb'}>
                    <Heading level={1}>{t(`title`)}</Heading>
                    <Greeting />
                </Layout.Item>
            </Layout>
            <Layout>
                <Layout.Item default={'2/3'} m={'1/1'}>
                    <Contracts />
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/1'}>
                    <MarketingCards />
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
