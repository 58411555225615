import React from 'react';
import { DataOverview } from '@cp-shared-5/frontend-ui';
import { Addresses } from 'common';
import { useTranslation } from 'react-i18next';
import { PostalAddressSection } from './postal-address/PostalAddressSection';
import { isEmpty } from 'lodash';

interface AddressesSectionProps {
    address?: Addresses;
}

export const AddressesSection: React.FC<AddressesSectionProps> = ({ address }) => {
    const { t } = useTranslation('my-profile');

    if (!address || isEmpty(address.postalAddress)) {
        return null;
    }

    return (
        <DataOverview title={t('addresses-section.title')} className={'u-pr-none u-pb-none'}>
            <PostalAddressSection address={address?.postalAddress} />
        </DataOverview>
    );
};
