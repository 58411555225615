import { CPDate } from '@cp-shared-5/common-utilities';

export type PrivateCustomerData = {
    customerType: 'Private';
    firstName?: string;
    lastName?: string;
    birthDate?: CPDate;
    vatNumber?: string;
};

export type BusinessCustomerData = {
    customerType: 'Business';
    legalRepresentantive?: string;
    companyName?: string;
    vatNumber?: string;
};

export type Identification = PrivateCustomerData | BusinessCustomerData;

export type IdentificationErrors = 'WRONG_CUSTOMER_TYPE';

export type AddressesErrors = 'WRONG_ADDRESS_TYPE';

export type CustomerType = Identification['customerType'];

export enum Gender {
    Female = 'female',
    Male = 'male',
}
