export function logoutPath(): string {
    return '/logout';
}

export function notFoundPagePath(): string {
    return '/not-found';
}

export function registrationPagePath(): string {
    return '/register';
}

export function loginPath(): string {
    return '/login';
}

export function notAuthorizedPagePath(): string {
    return '/not-authorized';
}

export function forbiddenPagePath(): string {
    return '/forbidden';
}

export function genericErrorPagePath(): string {
    return '/error';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}

export function loginRedirectPagePath(): string {
    return '/login-redirect';
}

export function faqPagePath(): string {
    return '/faq';
}

export function cookiePolicyPath(): string {
    return '/cookie-policy';
}

export function legalNoticePath(): string {
    return '/legal-notice';
}

export function privacyPolicyPath(): string {
    return '/privacy-policy';
}

export function myDocumentsPagePath(): string {
    return '/my-documents';
}

export function landingPagePath(): string {
    return '/';
}

export function myProfilePagePath(): string {
    return '/my-profile';
}

export function changeContactDetailsPath(): string {
    return '/my-profile/change-contact-details';
}

export function amortizationTablePath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/amortization-table/${encryptedContractNumber}`;
}
