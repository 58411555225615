import { MarketingSettingsWithCustomerType } from 'common';
import React from 'react';
import { ConsultView } from './consult-view/ConsultView';

type MarketingConsentSettingsSectionProps = {
    marketingConsentSettings?: MarketingSettingsWithCustomerType;
};

export const MarketingConsentSettingsSection: React.FC<MarketingConsentSettingsSectionProps> = ({
    marketingConsentSettings,
}) => {
    return <ConsultView marketingConsentSettings={marketingConsentSettings} />;
};
