import React from 'react';
import { Card } from '@vwfs-bronson/bronson-react';
import { Contract, FinancialContractType, isFinancialContract } from '../../../common';
import { ContractBody } from './body';
import { ConsumerCredit } from './summary/ConsumerCredit/ConsumerCredit';
import { AutoCredit } from './summary/AutoCredit/AutoCredit';
import { FinancialLeasing } from './summary/FinancialLeasing/FinancialLeasing';
import { OperativeLeasing } from './summary/OperativeLeasing/OperativeLeasing';
import { Insurance } from './summary/Insurance/Insurance';
import { useTranslation } from 'react-i18next';

export const ContractCard: React.FC<{ contract?: Contract; className?: string; defaultExpanded?: boolean }> = ({
    contract,
    className,
    defaultExpanded,
}) => {
    const { t } = useTranslation('contracts');

    if (!contract || !contract.contractNumber || !contract.encryptedContractNumber) {
        return null;
    }

    const getContractSummaryComponent = (): JSX.Element => {
        switch (contract.contractType) {
            case FinancialContractType.CONSUMER_CREDIT:
                return <ConsumerCredit contract={contract} />;
            case FinancialContractType.AUTO_CREDIT:
                return <AutoCredit contract={contract} />;
            case FinancialContractType.FINANCIAL_LEASING:
                return <FinancialLeasing contract={contract} />;
            case FinancialContractType.OPERATIVE_LEASING:
                return <OperativeLeasing contract={contract} />;
            default:
                return <Insurance contract={contract} />;
        }
    };

    const productName = t(`product-name.${contract.contractType}`);

    return (
        <Card
            element="article"
            expandable={isFinancialContract(contract)}
            title={`${productName} ${contract.contractNumber}`}
            className={`${className} c-card--expandable`}
            defaultExpanded={defaultExpanded}
            contentLarge={<ContractBody contract={contract} />}
        >
            {getContractSummaryComponent()}
        </Card>
    );
};
