import { ContractSummaryItemProps } from '@cp-shared-5/frontend-ui';
import { TFunction } from 'i18next';
import { BaseContract, formatAsDate } from '../../../../common';

export const getInactiveContractSummary = (
    t: TFunction,
    { startDate, endDate }: BaseContract,
): Array<ContractSummaryItemProps> => {
    const startDateValue = startDate ? formatAsDate(startDate) : '-';
    const endDateValue = endDate ? formatAsDate(endDate) : '-';
    const statusValue = t(`contract-statuses.properly-terminated`);
    return [
        { value: statusValue, label: t('contract-status'), testClass: 'contract-status' },
        {
            value: startDateValue,
            label: t('start-date'),
            testClass: 'start-date',
        },
        {
            value: endDateValue,
            label: t('end-date'),
            testClass: 'end-date',
        },
    ];
};
