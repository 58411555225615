import React, { useState } from 'react';
import { ContactDetails, EditStatus } from 'common';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-5/frontend-ui';
import { changeContactDetailsPath } from 'components/navigation/paths';
import { ConsultView } from './consult-view/ConsultView';
import { EditView } from './edit-view/EditView';

export type ContactSectionProps = {
    contactDetails?: ContactDetails;
};

export const ContactSection: React.FC<ContactSectionProps> = ({ contactDetails }) => {
    const { path } = useRouteMatch();
    const history = useHistory();

    const { onAction: onStart } = useAnalyticsActionTracker('onEditProfileContact');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileContactSubmitFailed',
        confirmSuccess: 'onEditProfileContactSuccess',
    });
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);

    const startEditing = (): void => {
        onStart();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changeContactDetailsPath());
    };

    const cancelEditing = (): void => {
        onCancel('Contact');
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
    };

    const finishEditing = (newEditStatus: EditStatus): void => {
        newEditStatus === EditStatus.SUCCESS ? onSuccess() : onError();

        setLastEditStatus(newEditStatus);
        history.push(path);
    };

    return (
        <Switch>
            <Route exact path={changeContactDetailsPath()}>
                <EditView contactDetails={contactDetails} cancelEditing={cancelEditing} finishEditing={finishEditing} />
            </Route>
            <Route path={path}>
                <ConsultView
                    contactDetails={contactDetails}
                    startEditing={startEditing}
                    lastEditStatus={lastEditStatus}
                />
            </Route>
        </Switch>
    );
};
