import { HeroImage, StaticPageLoadingPlaceholder, useAnalyticsErrorPageTracker } from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React, { useCallback } from 'react';
import { useForbiddenErrorPage } from './useForbiddenErrorPage';
import { useLogin } from '../../../auth/useLogin';

const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

export const ForbiddenErrorPage: React.FC = () => {
    const { cmsContent: forbiddenErrorPage, isLoading, loadingError } = useForbiddenErrorPage();
    useAnalyticsErrorPageTracker('accessDeniedError', !!forbiddenErrorPage);
    const login = useLogin();
    const onClick = useCallback(() => {
        login();
    }, [login]);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={forbiddenErrorPage?.pretitle}
            title={forbiddenErrorPage?.title || ''}
            subTitle={forbiddenErrorPage?.subTitle}
            buttonText={forbiddenErrorPage?.buttonText}
            clickHandler={onClick}
            loadingPlaceholder={<StaticPageLoadingPlaceholder withHeroTeaser={false} />}
            inverted
        />
    );
};
