export type ValidateTanStatus = {
    status: TanStatus;
};

export type ValidateTanRequest = {
    encryptedCustomerId: string;
    smsToken: string;
};

export type TanStatus = 'OK' | 'ERROR';

export enum TanVerificationError {
    WRONG_SMS_TOKEN = 'WRONG_SMS_TOKEN',
    EXPIRED_SMS_TOKEN = 'EXPIRED_SMS_TOKEN',
    IS_LOCKED_OUT = 'IS_LOCKED_OUT',
}
