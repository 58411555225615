import { Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';
import { EditStatus } from 'common/apis/services/types/edit-status';
import React from 'react';
import { useTranslation } from 'react-i18next';

type NotificationForLastEditStatusProps = {
    lastEditStatus: EditStatus;
};

export const NotificationForLastEditStatus: React.FC<NotificationForLastEditStatusProps> = ({ lastEditStatus }) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'contact-section.consult-view.edit-status';
    switch (lastEditStatus) {
        case EditStatus.SUCCESS: {
            return (
                <Notification
                    status={NotificationStatus.success}
                    headline={t(`${translationPrefix}.success.title`)}
                    text={t(`${translationPrefix}.success.text`)}
                    testId={'lastEditStatus'}
                />
            );
        }
        case EditStatus.ERROR: {
            return (
                <Notification
                    status={NotificationStatus.error}
                    text={t(`${translationPrefix}.error.text`)}
                    testId={'lastEditStatus'}
                />
            );
        }
        default: {
            return null;
        }
    }
};
