import { DataOverview } from '@cp-shared-5/frontend-ui';
import { AddressBase } from 'common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddressList } from './AddressList';

type ConsultViewProps = {
    address?: AddressBase;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ address }) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'addresses-section.postalAddress.common-view';

    return (
        <DataOverview title={t(`${translationPrefix}.title`)} withoutCardEffect={true} className={'u-pt-none'}>
            <AddressList address={address} translationPrefix={translationPrefix} />
        </DataOverview>
    );
};
