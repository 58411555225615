import React, { useState } from 'react';
import { Button, Fieldset, Form, Layout, Modal } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { RegistrationError, RegistrationResult } from 'common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-5/common-utilities';
import {
    CleaveInput,
    preventSubmit,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    ValidatedCheckbox,
    ValidatedInput,
} from '@cp-shared-5/frontend-ui';
import { businessIdentificationFormValidationSchema } from './validation';
import { handleSubmit } from './utils';
import { BusinessFormProps } from '../types';
import { legalNoticePath } from '../../../../navigation/paths';
import { useTrackerOnBeforeUnload } from '../../../../../hooks';

type BusinessIdentificationFormProps = {
    onSubmitClick: Function;
    onSuccess: (data: RegistrationResult, formData: BusinessFormProps) => void;
    onError: (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => void;
};

export const BusinessIdentificationForm: React.FC<BusinessIdentificationFormProps> = ({
    onSubmitClick,
    onSuccess,
    onError,
}) => {
    const { t } = useTranslation('registration');
    const setLastTouchedField = useTrackerOnBeforeUnload();
    const { onTyping, onSubmit: trackOnSubmit } = useAnalyticsFormTracker({
        startTyping: 'onStartTypingConfirmIdentity',
        confirm: 'onConfirmConfirmIdentiy',
    });

    const [showModal, setShowModal] = useState<boolean>(false);

    const { onAction } = useAnalyticsActionTracker('onFormValidationErrorConfirmIdentity');
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);
    const initialListErrors =
        'businessCustomerNumber, companyIdentificationNumber, companyName, confirmTermsAndConditions, confirmPrivacyPolicy';

    const onSubmit = (formValues: BusinessFormProps): void => {
        onSubmitClick();
        trackOnSubmit();
        handleSubmit(formValues, onSuccess, onError);
    };

    const labels = {
        contractNumber: t('businessCustomer.contractNumber'),
        companyVatNumber: t('businessCustomer.vatNumber'),
        confirmTermsAndConditions: t('termsAndConditions'),
        confirmPrivacyPolicy: t('privacyPolicyCheckbox'),
        privacyPolicyLink: t('privacyPolicyLink'),
    };

    return (
        <>
            <Layout.Item>
                <Formik
                    initialValues={{
                        contractNumber: '',
                        companyVatNumber: '',
                        confirmTermsAndConditions: false,
                        confirmPrivacyPolicy: false,
                    }}
                    validationSchema={businessIdentificationFormValidationSchema(t)}
                    onSubmit={onSubmit}
                >
                    {(formik) => (
                        <Form onSubmit={preventSubmit()} onChange={() => onTyping(formik.errors, formik.touched)}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <CleaveInput
                                        cleaveOptions={{
                                            numericOnly: true,
                                        }}
                                        inputMode="text"
                                        label={labels.contractNumber}
                                        name={'contractNumber'}
                                        hint={t('linkButton')}
                                        onLinkClick={(): void => setShowModal(true)}
                                        testId={'business-contract-number'}
                                        onFocus={() => setLastTouchedField(labels.contractNumber)}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ValidatedInput
                                        label={labels.companyVatNumber}
                                        name={'companyVatNumber'}
                                        testId={'company-id'}
                                        onFocus={() => setLastTouchedField(labels.companyVatNumber)}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ValidatedCheckbox
                                        label={
                                            <a href={legalNoticePath()} target="_blank" rel="noopener noreferrer">
                                                {labels.confirmTermsAndConditions}
                                            </a>
                                        }
                                        name="confirmTermsAndConditions"
                                        testId={'business-confirm-terms-and-conditions'}
                                        onFocus={() => setLastTouchedField(labels.confirmTermsAndConditions)}
                                    />
                                </Fieldset.Row>

                                <Fieldset.Row>
                                    <ValidatedCheckbox
                                        label={
                                            <a
                                                href={labels.privacyPolicyLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {labels.confirmPrivacyPolicy}
                                            </a>
                                        }
                                        name="confirmPrivacyPolicy"
                                        testId={'business-confirm-privacy-policy'}
                                        onFocus={() => setLastTouchedField(labels.confirmPrivacyPolicy)}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Button
                                        full={true}
                                        onClick={() => {
                                            formik.submitForm();
                                            if (!isEmpty(formik.errors) || isEmpty(formik.touched)) {
                                                const errorToString = getErrors(formik.errors).toString();
                                                if (!errorToString) {
                                                    onAction(initialListErrors);
                                                } else onAction(getErrors(formik.errors));
                                            }
                                        }}
                                        testId={'submit-business-registration-button'}
                                    >
                                        {t('confirmButton')}
                                    </Button>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </Layout.Item>
            <Modal
                shown={showModal}
                buttonConfirmText={t('modal.close')}
                onClose={(): void => setShowModal(false)}
                onConfirm={(): void => setShowModal(false)}
            >
                <h3>{t('modal.headline1')}</h3>
                <p dangerouslySetInnerHTML={{ __html: t('modal.text') }} />
            </Modal>
        </>
    );
};
