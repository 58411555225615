import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useMyProfile } from './useMyProfile';
import { MyProfileLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import React from 'react';
import { MyProfileUi } from './ui/MyProfileUi';

export const MyProfile: React.FC = () => {
    const { data, isLoading, loadingError } = useMyProfile();

    useAnalyticsPageViewTracker('profile', !!data);
    const MyProfileWithHandlers = withLoadingAndNoConnectionHandler(MyProfileUi);

    return (
        <MyProfileWithHandlers
            isLoading={isLoading}
            myProfileData={data}
            hasError={!!loadingError}
            loadingPlaceholder={<MyProfileLoadingPlaceholder />}
        />
    );
};
