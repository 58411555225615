import { AddressBase } from 'common';
import React from 'react';
import { ConsultView } from './consult-view/ConsultView';

type PostalAddressSectionProps = {
    address?: AddressBase;
};

export const PostalAddressSection: React.FC<PostalAddressSectionProps> = ({ address }) => {
    return <ConsultView address={address} />;
};
