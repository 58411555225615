import React from 'react';

import { LegalNoticePage } from 'common';
import { HeroImage } from '@cp-shared-5/frontend-ui';
import { ContentSection, OrderedList, Paragraph } from '@vwfs-bronson/bronson-react';

export const LegalNoticeUi: React.FC<{ legalNotice?: LegalNoticePage }> = ({ legalNotice }) => {
    if (!legalNotice) {
        return null;
    }
    const { teaser, content } = legalNotice;

    const teaserComponent = teaser ? <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow /> : null;

    const title = content?.title ? <h3>{content.title}</h3> : null;

    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium">
                {title}
                <Paragraph>{content.textP1}</Paragraph>
                <Paragraph>{content.textP2}</Paragraph>
                <OrderedList nestedCounter>
                    <OrderedList.Item>
                        <b>{content.list?.section1.title}</b>
                        <Paragraph className="u-pl-none u-pt-xsmall">{content.list?.section1.content}</Paragraph>
                    </OrderedList.Item>
                    <OrderedList.Item>
                        <b>{content.list?.section2.title}</b>
                        <Paragraph className="u-pl-none u-pt-xsmall">{content.list?.section2.content}</Paragraph>
                        <ol>
                            <OrderedList.Item>
                                <b>{content.list?.section2.subTitle1}</b>
                                <Paragraph className="u-pl-none u-pt-xsmall">
                                    {content.list?.section2.subContent1}
                                </Paragraph>
                            </OrderedList.Item>
                            <OrderedList.Item>
                                <b>{content.list?.section2.subTitle2}</b>
                                <Paragraph className="u-pl-none u-pt-xsmall">
                                    {content.list?.section2.subContent2}
                                </Paragraph>
                            </OrderedList.Item>
                            <OrderedList.Item>
                                <b>{content.list?.section2.subTitle3}</b>
                                <Paragraph className="u-pl-none u-pt-xsmall">
                                    {content.list?.section2.subContent3}
                                </Paragraph>
                            </OrderedList.Item>
                        </ol>
                    </OrderedList.Item>
                    <OrderedList.Item>
                        <b>{content.list?.section3.title}</b>
                        <Paragraph className="u-pl-none u-pt-xsmall">{content.list?.section3.content}</Paragraph>
                    </OrderedList.Item>
                    <OrderedList.Item>
                        <b>{content.list?.section4.title}</b>
                        <Paragraph className="u-pl-none u-pt-xsmall">{content.list?.section4.content}</Paragraph>
                    </OrderedList.Item>
                    <OrderedList.Item>
                        <b>{content.list?.section5.title}</b>
                        <Paragraph className="u-pl-none u-pt-xsmall">{content.list?.section5.content}</Paragraph>
                        <Paragraph className="u-pl-none u-pt-xsmall">{content.list?.section5.subTitle1}</Paragraph>
                        <Paragraph className="u-pl-none u-pt-xsmall">{content.list?.section5.subTitle2}</Paragraph>
                        <Paragraph className="u-pl-none u-pt-xsmall">{content.list?.section5.subTitle3}</Paragraph>
                        <Paragraph className="u-pl-none u-pt-xsmall">{content.list?.section5.subTitle4}</Paragraph>
                        <Paragraph className="u-pl-none u-pt-xsmall">{content.list?.section5.subTitle5}</Paragraph>
                    </OrderedList.Item>
                </OrderedList>
                <Paragraph>{content.textP3}</Paragraph>
                <Paragraph>
                    {content.textP4}
                    <a href="mailto:dpogr@vwfs.com">{content.email}</a>.
                </Paragraph>
                <Paragraph>{content.textP5}</Paragraph>
            </ContentSection>
        </>
    );
};
