export * from './cookie-policy-page';
export * from './error-page';
export * from './faq';
export * from './generic-error-page';
export * from './icon-footer';
export * from './legal-footer';
export * from './privacy-policy-page';
export * from './legal-notice-page';
export * from './marketing-cards';
export * from './simple-teaser';
export * from './logout-page';
