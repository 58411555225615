import React from 'react';

export type DownloadLinkProps = {
    label: string;
    href: string;
    fileInfo?: string;
    testId?: string;
};

// TODO: Remove when Bronson-React exposes this component
export const DownloadLink: React.FC<DownloadLinkProps> = ({ label, fileInfo, testId, href }) => (
    <a href={href} target="_blank" rel="noopener noreferrer" className="c-link  c-download-link" data-testid={testId}>
        <i className="c-link__icon  c-icon  c-icon--[semantic-download]" />
        <span className="c-link__body">
            <span className="c-link__effect">{label}</span>
            {fileInfo && <span className="c-download-link__file-info">({fileInfo})</span>}
        </span>
    </a>
);
