import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import {
    createAuthenticationComponent,
    createAuthorization,
    createDecoratedRoute,
    Spinner,
} from '@cp-shared-5/frontend-ui';
import {
    genericErrorPagePath,
    landingPagePath,
    notAuthorizedPagePath,
    registrationPagePath,
} from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import { getUserRegistryStatusEndpoint, UserRegistryStatus } from '../common';
import { getUrlSearchParams } from '../utils';
import { GenericErrorType } from '../components/error-pages/generic-error';

const errorTypeParam = getUrlSearchParams().get('errorType');

const commonAuthorizationOptions = {
    onLoading: <Spinner center />,
    onError: <Redirect to={landingPagePath()} />,
};

const Authentication = createAuthenticationComponent({
    onMissingAuthentication: <Redirect to={landingPagePath()} />,
});

const getOnMissingAuthorization = (): ReactNode => {
    if (!errorTypeParam) {
        return <Redirect to={registrationPagePath()} />;
    }
    if (errorTypeParam === GenericErrorType.INVALID_SESSION_ERR) {
        return <Redirect to={notAuthorizedPagePath()} />;
    }
    return <Redirect to={`${genericErrorPagePath()}?errorType=${errorTypeParam}`} />;
};

const { Authorization: UserRegistrationAuthorization } = createAuthorization({
    displayName: 'UserRegistrationAuthorization',
    authorizationDataProvider: async () => {
        if (!!errorTypeParam) {
            return { isAuthorized: false };
        }
        try {
            const { data: registrationData } = await CpDataApi.get<UserRegistryStatus>(getUserRegistryStatusEndpoint());
            return { isAuthorized: registrationData.isRegistered };
        } catch (e) {
            return { isAuthorized: false };
        }
    },
    onMissingAuthorization: getOnMissingAuthorization(),
    ...commonAuthorizationOptions,
});

export const AuthenticatedRoute = createDecoratedRoute('AuthenticatedRoute', Authentication);
export const AuthorizedRoute = createDecoratedRoute('AuthorizedRoute', UserRegistrationAuthorization);
